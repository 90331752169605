import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { isDesktop } from "react-device-detect"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./assets/styles/_index.scss"

const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const ImageCard = (props) => {
  // console.log(props)
  return props?.card_items?.length > 0 ? (
    <section className={`image-cards slider ${props?.bg_color} ${props?.choose_spacing}`}>
      <Container>
        <Row>
          <Col lg={12}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.primary_title && <h6>{props?.primary_title}</h6>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.secondary_title && <h2>{props?.secondary_title}</h2>}
            </ScrollAnimation>
          </Col>
        </Row>
        {props?.card_items?.length > 0 ? (
          <CustomSlider
            arrows={isDesktop}
            slidecount={props?.slide_count}
            className="card-items-slider"
            showFinite
          >
            {props?.card_items?.map((item, i) => {
              const imagename = props?.isGuide
                ? "community.image_cards_image.image"
                : "page.image_cards_image.image"

              let processedImages = JSON.stringify({})
              if (props?.imagetransforms?.image_cards_image_Transforms) {
                processedImages =
                  props?.imagetransforms?.image_cards_image_Transforms
              }
              return (
                <div className="icon-item">
                  <CTALink
                    class=""
                    link={
                      item?.cta_link
                        ? item?.cta_link
                        : { external_link: item?.custom_link }
                    }
                    title={item?.cta_label}
                    target_window={item?.link_type}
                  >
                    <ImageModule
                      ImageSrc={item?.image}
                      altText={item?.image?.alternativeText}
                      imagetransforms={processedImages}
                      renderer="srcSet"
                      imagename={imagename}
                      strapi_id={props?.id}
                      classNames="img-card"
                    />
                  </CTALink>
                  <CTALink
                    class="button button-link"
                    link={
                      item?.cta_link
                        ? item?.cta_link
                        : { external_link: item?.custom_link }
                    }
                    title={item?.cta_label}
                    target_window={item?.link_type}
                  />
                  {/* <div className="button button-link">{item?.cta_label}</div> */}
                </div>
              )
            })}
          </CustomSlider>
        ) : null}
      </Container>
    </section>
  ) : null
}

export default ImageCard
