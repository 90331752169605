import React, { useState, useEffect  } from "react"
import { Container } from "react-bootstrap"
import LoadExternalScript from "../../common/utils/load-external-script"
import "./SocialTab.scss"

const SocialTab = (props) => {
  const socialFilters = [
    { value: "instagram", label: "Instagram" },
    { value: "facebook", label: "Facebook" },
    { value: "twitter", label: "Twitter" },
    { value: "google", label: "google" },
  ]
  const [filter, setFilter] = useState("instagram")
  const instaScript = "https://apps.elfsight.com/p/platform.js";

  useEffect(()=>{
    if(props.social){
      setFilter(props.social)
    }

  })

  return (
    <div className="social-page-wrapper">
      {/* <Container>
        <div className="social-top-sections">
          <div className="categories-container">
            <div className="categories-list">
              <Select
                options={socialFilters}
                isSearchable={false}
                onChange={(e) => {
                  setFilter(e.value)
                }}
                placeholder="Instagram"
                className="select-control"
                classNamePrefix="react-select"
                // styles={customStylesNews}
                components={{
                  DropdownIndicator: () => (
                    <i className="icon down-arrow-light" />
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <ul className="inner-tab nav-tabs" id="filter-link">
              {socialFilters?.map((option) => (
                <li className="nav-item" key={option.value}>
                  <button
                    type="button"
                    className={`nav-link ${option.value} ${
                      option.value === filter ? "active tab_fill" : ""
                    }`}
                    onClick={(event) => {
                      setFilter(option.value)
                    }}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container> */}

      <div className="social_card-listing-wrapper">
        <Container>
          <div>
            {filter === "instagram" ? (
              <>
                {" "}
                <LoadExternalScript
                  src={instaScript}
                  // async="true"
                  defer="true"
                  // loadScript={renderMap}
                  // appendScriptTo="head"
                />
                <div className="elfsight-app-f3da8bda-fcc0-49ce-b954-19092515f1af" />
              </>
            ) : filter === "facebook" ? (
              <>Facebook</>
            ) : filter === "twitter" ? (
              <>Twitter</>
            ) : filter === "google" ? (
              <>
              {" "}
              <LoadExternalScript
                src={instaScript}
                // async="true"
                defer="true"
                // loadScript={renderMap}
                // appendScriptTo="head"
              />
              <div class="elfsight-app-e1aed371-d058-457b-b438-1b5d7ad05ffd" data-elfsight-app-lazy></div>
            </>
            ) : null}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default SocialTab
