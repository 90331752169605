import loadable from "@loadable/component"
import { navigate } from "@reach/router"
import { graphql } from "gatsby"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import Select from "react-select"
import PartnerFormFields from "../../static/forms/partner_form.json"
import RCFormFields from "../../static/forms/request_a_callback_form.json"

import ImageCard from "../components/ImageCard/ImageCard"
import SocialTab from "../components/SocialTab/SocialTab"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DefaultForm = loadable(() =>
  import("../components/forms/default-form-layout")
)
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const ListingIntroModule = loadable(() =>
  import("../components/ListingIntroModule/ListingIntroModule")
)
const ContactOffice = loadable(() =>
  import("../components/ContactOffice/ContactOffice")
)

const Teams = ({ data }, props) => {
  const PageData = data?.strapiPage
  const Offices = data?.allStrapiOffice.edges
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  const [showRC, setShowRC] = useState(false)
  const handleModalRC = () => {
    setShowRC(true)
  }

  const closeModalRC = () => {
    setShowRC(false)
  }
  const socialFilters = [
    { value: "call_back", label: "Request a call back" },
    { value: "gk", label: "General Enquiry" },
    { value: "partner", label: "Become a partner" },
    { value: "careers", label: "Careers at Baytify" },
  ]
  const [filter, setFilter] = useState("call_back")
  const onClick = (value) => {
    setFilter(value)
    if (value === "gk") {
      navigate("/contact/general-enquiry/")
    } else if (value === "partner") {
      handleModal()
    } else if (value === "call_back"){
      handleModalRC()
    } 
    else {
      navigate("/contact/careers/")
    }
  }
  return (
    <Layout dark popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top">
        <div className="pattern-bg">
          <BreadcrumbModule
            subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
            subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
            title={PageData.title}
            parentlabel={
              PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
            }
            parentlink={
              PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug
            }
          />
          <div className="white-bg">
            <ListingIntroModule
              content={PageData.banner?.banner_content}
              headingTitle={PageData.title}
            />
            {PageData.banner?.cta_1_title && (
              <Container>
                <div className="categories-list d-block d-md-none">
                  <Select
                    options={socialFilters}
                    isSearchable={false}
                    onChange={(e) => {
                      onClick(e.value)
                    }}
                    placeholder="Request a call back"
                    className="select-control"
                    classNamePrefix="react-select"
                    // styles={customStylesNews}
                    components={{
                      DropdownIndicator: () => (
                        <i className="icon down-arrow-light" />
                      ),
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <ul className="list-inline button-list d-none d-md-inline">
                  {PageData.banner?.cta_1_title && (
                    <li className="list-inline-item">
                      <ScrollAnimation
                        animateIn="animate__slideInUp"
                        delay={400}
                        animateOnce
                        offset={10}
                      >
                        {PageData.banner?.cta_1_title && PageData.banner?.cta_1_link ?
                        <CTALink
                          class="button button-primary-color"
                          link={PageData.banner?.cta_1_link ? PageData.banner?.cta_1_link : {
                            external_link: PageData.banner?.cta1_custom
                          }}

                          title={PageData.banner?.cta_1_title}
                          target_window={
                            PageData.banner?.cta_1_link?.target_window
                          }
                        />:
                         <a
                          href="javascript:void(0);"
                          className="button button-primary-color"
                          onClick={() => handleModalRC()}
                        >
                          {PageData.banner?.cta_1_title}
                        </a>}
                      </ScrollAnimation>
                    </li>
                  )}
                  {PageData.banner?.cta_2_title && (
                    <li className="list-inline-item">
                      <ScrollAnimation
                        animateIn="animate__slideInUp"
                        delay={500}
                        animateOnce
                        offset={10}
                      >
                         {PageData.banner?.cta_2_title && PageData.banner?.cta_2_link ?
                        <CTALink
                          class="button button-secondary-outline"
                          link={PageData.banner?.cta_2_link ? PageData.banner?.cta_2_link : {
                            external_link: PageData.banner?.cta2_custom
                          }}

                          title={PageData.banner?.cta_2_title}
                          target_window={
                            PageData.banner?.cta_2_link?.target_window
                          }
                        />:
                        <a
                          href="javascript:void(0);"
                          className="button button-secondary-outline"
                          onClick={() => handleModal()}
                        >
                          {PageData.banner?.cta_2_title}
                        </a>}
                      </ScrollAnimation>
                    </li>
                  )}
                  {PageData.banner?.cta_3_title &&
                     (
                      <li className="list-inline-item">
                        <ScrollAnimation
                          animateIn="animate__slideInUp"
                          delay={400}
                          animateOnce
                          offset={10}
                        >
                          {PageData.banner?.cta_3_title && PageData.banner?.cta_3_link ?
                          <CTALink
                            class="button button-secondary-outline"
                            link={PageData.banner?.cta_3_link ? PageData.banner?.cta_3_link : {
                              external_link: PageData.banner?.cta3_custom
                            }}
                            title={PageData.banner?.cta_3_title}
                            target_window={
                              PageData.banner?.cta_3_link.target_window
                            }
                          />:
                          <a
                            href="javascript:void(0);"
                            className="button button-secondary-outline"
                            onClick={() => handleModal()}
                          >
                            {PageData.banner?.cta_3_title}
                          </a>}
                        </ScrollAnimation>
                      </li>
                    )}
                      {PageData.banner?.cta_4_title &&
                    PageData.banner?.cta_4_link && (
                      <li className="list-inline-item">
                        <ScrollAnimation
                          animateIn="animate__slideInUp"
                          delay={400}
                          animateOnce
                          offset={10}
                        >
                          <CTALink
                            class="button button-secondary-outline"
                            link={PageData.banner?.cta_4_link ? PageData.banner?.cta_4_link : {
                              external_link: PageData.banner?.cta4_custom
                            }}
                            title={PageData.banner?.cta_4_title}
                            target_window={
                              PageData.banner?.cta_4_link.target_window
                            }
                          />
                        </ScrollAnimation>
                      </li>
                    )}
                </ul>
                <Modal
                  show={show}
                  onHide={() => setShow(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="more-filters-modal team-form"
                  id="filter-modal"
                  // fullscreen={"xl-down"}
                  backdrop="static"
                >
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={() => closeModal()}
                  >
                    <i className="icon black-cancel" />
                  </button>
                  <Modal.Body>
                    <div className="modal-body-container">
                      <h2>Become a partner</h2>
                      <DefaultForm
                        fields={PartnerFormFields}
                        formtagclassname="team-form"
                        // to_email_id={}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={showRC}
                  onHide={() => setShowRC(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="more-filters-modal team-form"
                  id="filter-modal"
                  // fullscreen={"xl-down"}
                  backdrop="static"
                >
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={() => closeModalRC()}
                  >
                    <i className="icon black-cancel" />
                  </button>
                  <Modal.Body>
                    <div className="modal-body-container">
                      <h2>Request a call back</h2>
                      <DefaultForm
                        fields={RCFormFields}
                        formtagclassname="team-form"
                        // to_email_id={}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
              </Container>
            )}
            {PageData?.add_page_modules?.length > 0 &&
              PageData.add_page_modules?.map((module, index) => {
                return (
                  <div>
                    {/* {module.strapi_component === "page-modules.plain-content" &&
                      index === 0 && (
                        <ListingIntroModule
                          content={module.content}
                          headingTitle={
                            module.title ? module.title : PageData.title
                          }
                        />
                      )} */}
                    {module.strapi_component === "page-modules.global-module" &&
                      module.select_module === "offices" && (
                        <ContactOffice Offices={Offices} {...module} />
                      )}
                    {module.strapi_component === "page-modules.global-module" &&
                      module.select_module === "social" && (
                        <SocialTab {...module} />
                      )}
                    {module?.strapi_component ===
                      "page-modules.image-with-page" && (
                      <ImageCard
                        {...module}
                        slide_count="4"
                        id={PageData.strapi_id}
                        imagetransforms={PageData.imagetransforms}
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  const pageurl = typeof window !== "undefined" ? window.location.href : ""


  const ldJson = {
    "@context": "http://www.schema.org",
    "@type": "RealEstateAgent",
    "name": "Baytify",
    "url": pageurl,
    "logo": "https://ggfx-baytify.s3.eu-west-2.amazonaws.com/i.prod/baytify_email_logo_5da2bf6ad8.png",
    "image": "https://ggfx-baytify.s3.eu-west-2.amazonaws.com/i.prod/img_17_79d2688f6f.jpg",
    "description": PageData?.seo?.metaDescription
    ? PageData?.seo?.metaDescription
    : PageData?.title,
    "address": {
    "@type": "PostalAddress",
    "addressCountry": "Dubai"
    },
    "geo": {
    "@type": "GeoCoordinates",
    "latitude": "25.490912",
    "longitude": "55.942490",
    },
    "telephone": "+971 52 1000 367",
    "contactPoint":{
    "@type": "ContactPoint",
    "contactType": "Sales",
    "telephone": "+971 52 1000 367"
    }
    }
    
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    >
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        /> 
    </SEO>
  )
}

export default Teams

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          ...PlainContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_WITH_PAGE {
          id
          secondary_title
          strapi_component
          primary_title
          bg_color
          card_items {
            image {
              url
              alternativeText
            }
            cta_label
            cta_link {
              slug
              strapi_parent {
                slug
              }
            }
          }
        }
      }
    }
    allStrapiOffice(filter: { publish: { eq: true } }) {
      edges {
        node {
          ...OfficeFragment
        }
      }
    }
  }
`
